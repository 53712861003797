import {Component, Input, Output, OnInit, OnChanges, EventEmitter, SimpleChanges} from '@angular/core';
import {CampoAggiuntivo, CampoUtility} from "../models/campo-aggiuntivo";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewsComponent } from 'src/app/task/news/news.component';


@Component({
  selector: 'app-dynamic-col-table',
  templateUrl: './DynamicColTable.component.html',
  styleUrls: ['./DynamicColTable.component.scss']
})
export class DynamicColTable implements OnInit {


  @Input() hasSave: boolean = false;
  @Input() hasNote: boolean = false;
  @Output() onSaveClick = new EventEmitter<any>();

    @Input() rows
    @Output() rowsChange = new EventEmitter<any[]>();
    /*= [
        {id: 286, nominativo: "Anna Romano"},
    {id: 293, nominativo: "Fabio Fabbri"}
    ]*/
    @Input() columns
    @Output() columnsChange= new EventEmitter<any[]>();
    @Input() CampiColonne: {nome: string,  param:string|number}[];

    @Output() Refresh = new EventEmitter();
    constructor(
      private modalService: NgbModal,
      private campoUtility: CampoUtility
    ) {}

    ngOnInit(){

    }

    Save(){
      this.onSaveClick?.emit({rows: this.rows, columns: this.columns});
    }


  attivitatooltip(attivita) {
    return `${attivita.nome ?? ''} \n- ${attivita.managerObject?.fullName ?? ''} \n- ${attivita.descrizione ?? ''} \n ${attivita.note ?? ''}`;
  }
OpenTask(task){
   var dynamicFields = this.campoUtility.getDynamicTaskSctructure(task.type, task.tipo);
  let Newsmodal = this.modalService.open(NewsComponent, {
    centered: true,
    backdrop: 'static',
    size: 'xl',
  });
  (<NewsComponent>Newsmodal.componentInstance).DynamicFields = dynamicFields;
  (<NewsComponent>Newsmodal.componentInstance).IsModal = true;
  (<NewsComponent>Newsmodal.componentInstance).Item = task ;
  (<NewsComponent>Newsmodal.componentInstance).updated.subscribe(data => {
   this.Refresh.emit();
  });
}
  protected readonly CampoAggiuntivo = CampoAggiuntivo;
}




