<div class="bg-white" style="    overflow-x: scroll;">
    <table class="table">
        <thead>
            <tr>
                <td></td>
                <td *ngFor="let attivita of columns" [colSpan]="CampiColonne?.length ?? 3" [ngbTooltip]="attivitatooltip(attivita)"><a href="javascript:void()" (click)="OpenTask(attivita)">{{attivita?.data_inizio_prevista | date: 'dd/MM/yyyy'}}</a></td>
            </tr>
            <tr>
                <td>Ospiti</td>
              <ng-container *ngIf="!CampiColonne "><td *ngFor="let attivita of columns" colspan="3"> Valutazioni</td></ng-container>
              <ng-container *ngIf="CampiColonne "><ng-container *ngFor="let attivita of columns"> <td *ngFor="let campi of CampiColonne" > {{campi.nome}}</td></ng-container></ng-container>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let cliente of rows">
              <tr style="border-bottom: none;">
                <td  style="border-bottom: none;">{{cliente.nominativo}}</td>
                <ng-container *ngFor="let column of columns; let i = index">
                    <ng-container *ngFor="let attivita of column.esiti; let j = index">
                        <ng-container  *ngIf="cliente.id == attivita.cliente">
                          <ng-container  *ngIf="columns[i]['esiti'][j]['esiti'] && !CampiColonne ">

                            <td  style="border-bottom: none;"><input style="    max-width: 3rem;" [(ngModel)]="columns[i]['esiti'][j]['esiti'][0]"></td>
                            <td  style="border-bottom: none;"><input style="    max-width: 3rem;" [(ngModel)]="columns[i]['esiti'][j]['esiti'][1]"></td>
                            <td  style="border-bottom: none;"><input style="    max-width: 3rem;" [(ngModel)]="columns[i]['esiti'][j]['esiti'][2]"></td>

                          </ng-container>
                          <ng-container  *ngIf="columns[i]['esiti'][j]['esiti'] && CampiColonne ">

                            <td  style="border-bottom: none;" *ngFor="let value of CampiColonne"><input style="    max-width: 4rem;" [(ngModel)]="columns[i]['esiti'][j]['esiti'][value.param]"></td>

                          </ng-container>
                          <ng-container  *ngIf="!columns[i]['esiti'][j]['esiti']">

                            <td  style="border-bottom: none;" [colSpan]="CampiColonne?.length ?? 3" style="text-align: center;">Assente</td>

                          </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
              </tr>
              <tr *ngIf="hasNote" style="border-top: none;">
                <td style="border-top: none;"></td>
                <ng-container *ngFor="let column of columns; let i = index">
                  <ng-container *ngFor="let attivita of column.esiti; let j = index">
                      <ng-container  *ngIf="cliente.id == attivita.cliente">
                        <ng-container  *ngIf="columns[i]['esiti'][j]['esiti'] && CampiColonne ">
                         <td style="border-top: none;" [colSpan]="CampiColonne?.length ?? 3"> <textarea [ngStyle]="{width: '100%'}" [(ngModel)]="columns[i]['note']"></textarea> </td>
                        </ng-container>
                        <ng-container  *ngIf="!columns[i]['esiti'][j]['esiti']">
                          <td style="border-top: none;" [colSpan]="CampiColonne?.length ?? 3" style="text-align: center;"></td>
                        </ng-container>
                      </ng-container>
                  </ng-container>
                </ng-container>
                
              </tr>
            </ng-container>
        </tbody>
    </table>
    <div class="m-t-10" *ngIf="hasSave">
      <button type="submit" type="button" [ngClass]="'btn'"    (click)='Save()'>
        <i [ngClass]="'fas f text-info'" class="font-14 mr-1 "></i>
        <span [ngClass]="'text-info'"> {{'Fine' | translate}} </span>
      </button>
    </div>
</div>
